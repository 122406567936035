<template>
    <AuthLayout>
        <div class="row my-3">
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <OverviewCard
                    title="Subscribed Users"
                    color="primary"
                    icon="users"
                    @click.native="$router.push({ name: 'users' })"
                >
                    214
                </OverviewCard>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <OverviewCard
                    title="Associated Retailers"
                    color="warning"
                    icon="building"
                    @click.native="$router.push({ name: 'retailers' })"
                >
                    143
                </OverviewCard>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <OverviewCard
                    title="Files Uploaded"
                    color="success"
                    icon="files-o"
                    @click.native="$router.push({ name: 'home' })"
                >
                    12345
                </OverviewCard>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <OverviewCard
                    title="Memory Used"
                    color="danger"
                    icon="cloud-download"
                    @click.native="$router.push({ name: 'home' })"
                >
                    4.20 GB
                </OverviewCard>
            </div>
        </div>
        <div class="card-group">
            <div class="card">
                <img class="card-img-top" src="@/assets/img/backgrounds/background-login.jpg" height="300">
                <div class="p-5">
                    <h5 class="card-title">Lifebox Admin</h5>
                    <p class="card-text">Welcome, Lifebox Administrator!</p>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top" src="@/assets/img/backgrounds/background-forgot-password.jpg" height="300">
                <div class="p-5">
                    <h5 class="card-title">See Users, Retailers, Notifications</h5>
                    <p class="card-text">A portal to all Lifebox details and alike.</p>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top" src="@/assets/img/backgrounds/background-register.jpg" height="300">
                <div class="p-5">
                    <h5 class="card-title">Create Users, Retailers, Notifications</h5>
                    <p class="card-text">Manage them as they access the Lifebox domain.</p>
                </div>
            </div>
        </div>
    </AuthLayout>
</template>

<script>
    export default {
        name: 'Home',
    }
</script>